// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-brand-identity-development-js": () => import("./../../../src/pages/brand-identity-development.js" /* webpackChunkName: "component---src-pages-brand-identity-development-js" */),
  "component---src-pages-conditions-js": () => import("./../../../src/pages/conditions.js" /* webpackChunkName: "component---src-pages-conditions-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-customised-content-writing-blogging-js": () => import("./../../../src/pages/customised-content-writing-blogging.js" /* webpackChunkName: "component---src-pages-customised-content-writing-blogging-js" */),
  "component---src-pages-digital-marketing-services-js": () => import("./../../../src/pages/digital-marketing-services.js" /* webpackChunkName: "component---src-pages-digital-marketing-services-js" */),
  "component---src-pages-e-commerce-development-js": () => import("./../../../src/pages/e-commerce-development.js" /* webpackChunkName: "component---src-pages-e-commerce-development-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-app-development-js": () => import("./../../../src/pages/mobile-app-development.js" /* webpackChunkName: "component---src-pages-mobile-app-development-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-quote-js": () => import("./../../../src/pages/quote.js" /* webpackChunkName: "component---src-pages-quote-js" */),
  "component---src-pages-social-media-management-js": () => import("./../../../src/pages/social-media-management.js" /* webpackChunkName: "component---src-pages-social-media-management-js" */),
  "component---src-pages-website-design-development-js": () => import("./../../../src/pages/website-design-development.js" /* webpackChunkName: "component---src-pages-website-design-development-js" */)
}

